import React, { useEffect, useState, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import DefaultLayout from '../../layouts/DefaultLayout';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {doHotelSearch} from "../../store/actions/hotel";
import {Row, Col, Form, Button, InputGroup, ProgressBar, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import "yet-another-react-lightbox/styles.css";
import { Rating } from 'react-simple-star-rating';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const HotelSearch = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const filterBy = () => true;

    const images = [
        {src: "https://images.unsplash.com/photo-1542103749-8ef59b94f47e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/2.jpg"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/3.jpg"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/4.jpg"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/5.jpg"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/6.jpg"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/7.jpg"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/8.jpg"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/9.jpg"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/10.jpg"},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/11.jpg"},
     ];

    const [htlName, setHtlName] = useState('');
    const [ratingValue, setRatingValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [destinatinOpt, setDestinatinOpt] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState(destinatinOpt);
    //console.log("selectedDestination", selectedDestination)

    const destinationSearch = async (query) => {
        setIsLoading(true);
        setDestinatinOpt([]);
        const res = await axios.get(process.env.REACT_APP_AUTO_SUGGEST_API+'/api/v1/autosuggest/cities?query='+query)
        setDestinatinOpt(res.data.cities);
        setIsLoading(false);
    };

    const handleRating = (rate) => {
        setRatingValue(rate)
    }
    const srchHotel = () => {
        if(selectedDestination && selectedDestination.length){
            let htlSrchObj = {
                "clientCode": "client01",
                "hotelName": htlName,
                "culture": "",
                "rating": ratingValue,
                "masterDestinationId": selectedDestination[0].aqDestinationId,
                "pageSize": 100,
                "pageNumber": 0
            }
            dispatch(doHotelSearch(htlSrchObj));
        }
        else if(htlName){
            let htlSrchObj = {
                "clientCode": "client01",
                "hotelName": htlName,
                "culture": "",
                "rating": ratingValue,
                "pageSize": 100,
                "pageNumber": 0
            }
            dispatch(doHotelSearch(htlSrchObj));
        }
        else{
            toast.error("Please select Hotel Name or Destination",{theme: "colored"})
        }
    }
    const HotelResult = useSelector((state) => state.hotelList);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(50);  
    const [pagesCount, setPagesCount] = useState(0);

    useEffect(()=>{
        if(HotelResult && HotelResult.htlResObj){
            setPagesCount(Math.ceil(HotelResult.htlResObj.masterHotels.length / pageSize))
            setCurrentPage(0)
        }
        
    },[HotelResult]);

    const handleClick = (inde) => {
        setCurrentPage(inde)
    };

    const selectHotel = (aqHotelId) => {
        navigate(`/hotelDetails`,{state:{aqHotelId}});
    }
    //console.log("HotelResult", HotelResult)

    const [options, setOptions] = useState([
        {name: 'Master', id: 'Master'},
        {name: 'EAN', id: 'EAN'},
        {name: 'PriceLine', id: 'PriceLine'},
        {name: 'Saber', id: 'Saber'},
      ]);

    const labelRef = useRef();
    const onChangeLecture = (value) => {
       
    };
    

  return (
    <>
    <ToastContainer />
    <DefaultLayout>
        <div className="px-md-4 px-3 py-4">
            <div className="pageHeading px-2"><h2>Hotel Search</h2></div>
            <div className="bg-white shadow rounded">
                <div className="p-3">
                    <Row className="gx-3">
                        {/* <Col md={2}>
                            <Form.Group className="mb-3">
                                <Form.Label className="ms-1 mb-1">Source Name</Form.Label>
                                <Form.Select className="shadow-sm fn14" size="lg">
                                    <option>--Select--</option>
                                    <option value="1">Master Data</option>
                                    <option value="2">EAN</option>
                                    <option value="3">Priceline</option>
                                    <option value="4">Saber</option>
                                </Form.Select>
                            </Form.Group>
                        </Col> */}
                        

                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="ms-1 mb-1">Hotel Name</Form.Label>
                                <Form.Control className="shadow-sm fn14" size="lg" placeholder="Enter Hotel Name" onChange={(e) => setHtlName(e.target.value)} />
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="ms-1 mb-1">Destination</Form.Label>
                                <AsyncTypeahead clearButton
                                    highlightOnlyResult={true}
                                    filterBy={filterBy}
                                    id="async-example"
                                    isLoading={isLoading}
                                    labelKey={(option) => `${option.cityName}, ${option.countryName}`}
                                    minLength={3}
                                    onSearch={destinationSearch}
                                    options={destinatinOpt}
                                    placeholder="Please Enter Destination"
                                    className="typeHeadDropdown"
                                    selected={selectedDestination}
                                    onChange={setSelectedDestination}
                                    inputProps={{
                                    className: 'form-control-lg shadow-sm fn14',
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={3}>
                            <Form.Group className="mb-3">
                                <Form.Label className="ms-1 mb-1">Hotel Rating</Form.Label>
                                <div className="form-control form-control-lg shadow-sm fn14 starCol d-flex justify-content-between py-1">
                                    <Rating
                                    initialValue={ratingValue}
                                    onClick={handleRating}
                                    size={27} />
                                    <span className="badge bg-warning text-dark lh-base">{ratingValue}</span>
                                </div>
                                {ratingValue !== 0 &&
                                    <div className="fn13 text-end mb-np5"><Link onClick={()=>setRatingValue(0)}>Reset Rating</Link></div>
                                }
                                
                            </Form.Group>
                        </Col>

                        <Col md={1}>
                            <Form.Group className="mb-3">
                                <Form.Label className="ms-1 mb-1">&nbsp;</Form.Label>
                                <div className="text-end"><Button onClick={()=> srchHotel()} variant="primary" className="w-100 py-2">&nbsp;Search&nbsp;</Button></div>
                             </Form.Group>
                        </Col>
                    </Row>

                    {/* <Row className="gx-3 justify-content-end">
                        <Col md={3} className="text-end">
                            <Button onClick={()=> srchHotel()} variant="primary" className="px-4 py-2">&nbsp;Search&nbsp;</Button>
                        </Col>
                    </Row> */}
                </div>
            </div>

            <div className="py-4">
            

               {HotelResult &&           
                <>
                {HotelResult.htlResObj ?
                <>
                { HotelResult.htlResObj.masterHotels && HotelResult.htlResObj.masterHotels.length ? 
                <>
                {/* <div className="sortTop">
                    <Row className="justify-content-between gx-3">
                        <Col lg={4} sm={8} className="my-1">
                            <InputGroup size="sm">
                                <Form.Control placeholder="Search Location, Hotel, or Address" className="fn13 border-end-0" />
                                <InputGroup.Text className="bg-white"><FontAwesomeIcon icon={solid('magnifying-glass')} /></InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col lg={2} sm={4} className="my-1">
                            <Form.Select size="sm">
                                <option value="0">--Sort by Supplier--</option>
                                <option value="1">EAN</option>
                                <option value="2">Priceline</option>
                                <option value="3">BookingCom</option>
                            </Form.Select>
                        </Col>
                        
                    </Row>
                </div> */}

                {HotelResult.htlResObj.masterHotels.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((v, i) => {
                    return (
                        <div className="htlboxcol rounded" key={i}>
                            <div className="d-md-flex">
                                <div className="hotelImg">
                                    <div style={{ width: "100%", maxWidth: "900px", aspectRatio: "3 / 2" }}>
                                        <Lightbox slides={images} plugins={[Inline]} />
                                    </div>
                                </div>
                                <div className="px-3 pt-2 w-100 d-flex flex-column">
                                    <div className="d-md-flex justify-content-between">
                                        <div>
                                            <h3 className="nameTitle mb-1 text-primary" onClick={()=>selectHotel(v.aqHotelId)} role="button">{v.name}</h3>
                                            {v.rating && 
                                                <div className="mb-1">
                                                    {v.rating.starRating &&
                                                    <span className="d-inline-flex">
                                                    {
                                                    Array.apply(null, { length:parseInt(v.rating.starRating)}).map((e, i) => (
                                                        <FontAwesomeIcon key={i} icon={solid('star')} className="stargold fn13" />
                                                    ))
                                                    }
                                                    </span>
                                                    }

                                                    {v.rating.tripAdvisorRating &&
                                                    <>&nbsp;
                                                    { !(v.rating.tripAdvisorRating.toString().indexOf('.') > -1) ?
                                                    <img style={{maxWidth:"100px"}} src={`http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${v.rating.tripAdvisorRating}.0-13387-4.png`} alt="tripadvisor" />
                                                    :
                                                    <img style={{maxWidth:"100px"}} src={`http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${v.rating.tripAdvisorRating}-13387-4.png`} alt="tripadvisor" />
                                                    }
                                                    </>
                                                    }
                                                </div>
                                            }

                                            
                                            <div className="mb-2 fw-light fn13">
                                            {v.address && 
                                                <>
                                                {v.address.line1 && v.address.line1} {v.address.line2 && ','+v.address.line2} {v.address.cityName && ','+v.address.cityName} {v.address.countryName && ','+v.address.countryName}
                                                </>
                                            }
                                            </div>
                                        </div>
                                        <div className="semibold">
                                            Master Hotel Id - {v.masterHotelId && v.masterHotelId}
                                        </div>
                                    </div>
                                    
                                    <div className="text-muted fw-light fn13">
                                        {v.description && 
                                        <p>{v.description.substring(0,180)+'...'}</p>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between mt-auto">
                                        <div>
                                            {/* <Form.Select size="sm">
                                                <option value="1">Mapped Source Hotels</option>
                                            </Form.Select> */}
                                            <InputGroup className="multiSelectDiv">
                                                <Multiselect className="form-control form-control-sm rounded-0 rounded-start border-end-0 pe-0"
                                                    
                                                    options={v.mappedHotels} 
                                                   
                                                    displayValue="contentSource"
                                                    placeholder=""
                                                    onKeyPressFn={function noRefCheck(){}}
                                                    onRemove={function noRefCheck(){}}
                                                    onSearch={function noRefCheck(){}}
                                                    onSelect={function noRefCheck(){}}
                                                    showCheckbox
                                                    selectionLimit={2}
                                                    />
                                                    <span className="border border-start-0 pe-1 rounded-end d-flex align-items-center"><FontAwesomeIcon icon={solid('caret-down')} className="text-secondary fn12" /></span>
                                            </InputGroup>
                                        </div>
                                        <div><p><Button variant="primary" size="sm">Compare Hotels</Button></p></div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    )
                })
                }
                {pagesCount >= 2 &&
                    <Pagination size='sm' className="justify-content-end mb-3">
                    <Pagination.First disabled={currentPage <= 0} onClick={() => handleClick(0)} />
                    <Pagination.Prev disabled={currentPage <= 0} onClick={() => handleClick(currentPage - 1)} />
                    {[...Array(pagesCount)].map((page, i) => 
                        <Pagination.Item active={i === currentPage} key={i} onClick={() => handleClick(i)}>
                            {i + 1}
                        </Pagination.Item>
                    )}
                    <Pagination.Next disabled={currentPage >= pagesCount - 1} onClick={() => handleClick(currentPage + 1)} />
                    <Pagination.Last disabled={currentPage >= pagesCount-1} onClick={() => handleClick(pagesCount-1)} />
                    </Pagination>
                }
                </>
                :
                <div>No Result Found</div>
                }
                </>
                :
                <>
                <ProgressBar animated now={100} style={{height:'8px',marginBottom:'10px'}} />
                <div>
                    {Array.apply(null, { length:5}).map((e, i) => (
                    <div className="htlboxcol rounded" key={i}>
                        <div className="d-md-flex">
                            <div className="hotelImg"></div>
                            <div className="px-3 pt-2 w-100">
                                <div className="d-md-flex justify-content-between placeholder-glow">
                                    <div className="placeholder-glow" style={{width:'200px'}}>
                                        <h3 className="placeholder col-12 bg-secondary"></h3>
                                        <div className="placeholder col-12 bg-secondary"></div>
                                    </div>
                                    <div className="placeholder bg-secondary" style={{width:'150px',height:'20px'}}></div>
                                </div>
                                
                                <div className="placeholder-glow">
                                    <p className="placeholder col-12 bg-secondary">&nbsp;</p>
                                </div>
                                <div className="d-flex justify-content-between mb-4 placeholder-glow">
                                    <div className="placeholder col-6 bg-secondary"></div>
                                    <div className="placeholder col-6 bg-secondary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
                    }
                </div>
                </>
                }
                </>
                }   
            </div>
        </div>
    </DefaultLayout>
    </>
  )
}

export default HotelSearch

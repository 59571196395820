import React from "react"
import Header from "../components/header/Header"
import Sidebar from "../components/sidebar/Sidebar"

class DefaultLayout extends React.Component {
  state = {
    toggleClass: true,
   }

  onClickFunction = (childData) => {
  this.setState({toggleClass: childData})
  }

  render(){
    const {toggleClass} = this.state;
    return (
      <div className="wrapper">
        <Header update={this.onClickFunction} />
        <div className={"middle " + (toggleClass ? 'slideDiv' : '')}>
            <Sidebar />
            <div className="rightSection">
              {this.props.children}
            </div>
        </div>
    </div>
    )
  }
}
export default DefaultLayout;
import {RESET_CNTQUALITY_RES, SET_CNTQUALITYRES } from "../types/contentTypes";
  
const intialState = {
  cntQualityObj:null,
};

export const cntQualityListReducer = (state = intialState.cntQualityObj, { type, payload }) => {
  switch (type) {
    case RESET_CNTQUALITY_RES:
      return { ...state, cntQualityObj: null};
    case SET_CNTQUALITYRES:
      return { ...state, cntQualityObj: payload };
    
    default:
      return state;
  }
};



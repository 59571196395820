import React, {useEffect, useState } from 'react';
import {useLocation } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import DefaultLayout from '../../layouts/DefaultLayout';
import {Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {getHotelCmpr} from "../../store/actions/hotel";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";

const HotelCompare = () => {
    const dispatch = useDispatch();
    const qry = useLocation(); 

    useEffect(()=>{
        if(qry && qry.state){
            dispatch(getHotelCmpr(qry.state.aqHotelId));
        }
    },[dispatch]);

    const HotelCmpr = useSelector((state) => state.hotelCmpr);
    //console.log("HotelCmpr", HotelCmpr)
    const [activeMarker, setActiveMarker] = useState(true);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPAPIKEY
    })
    const [showMapModal, setShowMapModal] = useState(false);

  return (
    <>
    <DefaultLayout>
        <div className="px-md-4 px-3 py-4">
            <div className="pageHeading px-2"><h2>Hotel Compare</h2></div>
            <div className="bg-white shadow-sm rounded">
                <div className="d-flex justify-content-between p-3 pb-2">
                    <div><h3 className="fs-5">Signature Inn Hotel Deira - EAN vs Priceline</h3></div>
                    <div><Button variant="primary" className="px-4"><FontAwesomeIcon icon={solid('hotel')} className="fn15" /> Select Hotel</Button></div>
                </div>
                <div className="px-3">
                {HotelCmpr &&
                <>
                { HotelCmpr.htlCmprObj ? 
                <>
                { HotelCmpr.htlCmprObj.masterHotel ? 
                <>
                    <div className="table-responsive">
                        <div className="mb-4">
                            <table className="table table-striped m-0 table-bordered rounded">
                                <tbody>
                                    <tr>
                                        <td style={{width:'190px'}} className="align-top"></td>
                                        <td style={{width:'330px',minWidth:'250px'}} className="align-top border-left">
                                            <div>
                                                <h4 className="fs-6 py-2">Master Hotel Id - {HotelCmpr.htlCmprObj.masterHotel.masterHotelId && HotelCmpr.htlCmprObj.masterHotel.masterHotelId}</h4>
                                                <div className="mb-3"><img className="rounded" src="https://naver.github.io/egjs-infinitegrid/assets/image/1.jpg" alt="" style={{maxHeight:'200px'}} /></div>
                                                <h3 className="fs-6">{HotelCmpr.htlCmprObj.masterHotel.name}</h3>
                                                
                                                <div>
                                                {HotelCmpr.htlCmprObj.masterHotel.rating &&
                                                <>
                                                    {HotelCmpr.htlCmprObj.masterHotel.rating.starRating &&
                                                        <span className="d-inline-flex">
                                                        {
                                                        Array.apply(null, { length:parseInt(HotelCmpr.htlCmprObj.masterHotel.rating.starRating)}).map((e, i) => (
                                                            <FontAwesomeIcon key={i} icon={solid('star')} className="stargold fn15" />
                                                        ))
                                                        }
                                                        </span>
                                                    }
                                                </>
                                                }
                                                </div>
                                                <div className="text-muted mb-2 fn14">
                                                {HotelCmpr.htlCmprObj.masterHotel.address && 
                                                    <>
                                                    {HotelCmpr.htlCmprObj.masterHotel.address.line1 && HotelCmpr.htlCmprObj.masterHotel.address.line1} {HotelCmpr.htlCmprObj.masterHotel.address.line2 && ','+HotelCmpr.htlCmprObj.masterHotel.address.line2} {HotelCmpr.htlCmprObj.masterHotel.address.cityName && ','+HotelCmpr.htlCmprObj.masterHotel.address.cityName} {HotelCmpr.htlCmprObj.masterHotel.address.countryName && ','+HotelCmpr.htlCmprObj.masterHotel.address.countryName}
                                                    </>
                                                }
                                                </div>
                                                <h4 className="fs-6">Rs. 23,030</h4>
                                            </div>
                                        </td>
                                        <td style={{width:'330px',minWidth:'250px'}} className="align-top">
                                            <div>
                                                <h4 className="fs-6 py-2">EAN Hotel Id - 58103</h4>
                                                <div className="mb-3"><img className="rounded" src="https://naver.github.io/egjs-infinitegrid/assets/image/4.jpg" alt="" style={{maxHeight:'200px'}} /></div>
                                                <h3 className="fs-6">Signature Inn Hotel Deira</h3>
                                                <div><span className="d-inline-flex"><FontAwesomeIcon icon={solid('star')} className="stargold fn15" /><FontAwesomeIcon icon={solid('star')} className="stargold fn15" /><FontAwesomeIcon icon={solid('star')} className="stargold fn15" /></span></div>
                                                <div className="text-muted mb-2 fn14">Al Rigga Road, Dubai</div>
                                                <h4 className="fs-6">Rs. 22,300</h4>
                                            </div>
                                        </td>
                                        <td style={{width:'330px',minWidth:'250px'}} className="align-top">
                                            <div>
                                                <h4 className="fs-6 py-2">Priceline Hotel Id - 54605</h4>
                                                <div className="mb-3"><img className="rounded" src="https://naver.github.io/egjs-infinitegrid/assets/image/6.jpg" alt="" style={{maxHeight:'200px'}} /></div>
                                                <h3 className="fs-6">Signature Inn Hotel Deira</h3>
                                                <div><span className="d-inline-flex"><FontAwesomeIcon icon={solid('star')} className="stargold fn15" /><FontAwesomeIcon icon={solid('star')} className="stargold fn15" /><FontAwesomeIcon icon={solid('star')} className="stargold fn15" /></span></div>
                                                <div className="text-muted mb-2 fn14">Al Rigga Road, Dubai</div>
                                                <h4 className="fs-6">Rs. 24,800</h4>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-top"><strong>Description</strong></td>
                                        <td className="align-top">
                                            <div className="text-muted fn13">
                                            {HotelCmpr.htlCmprObj.masterHotel.description && 
                                            <>{HotelCmpr.htlCmprObj.masterHotel.description}</>
                                            }
                                            </div>
                                        </td>
                                        <td className="align-top">
                                            <div className="text-muted fn13">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the text ever since the scrambled it to make a type specimen book.</div>
                                        </td>
                                        <td className="align-top">
                                            <div className="text-muted fn13">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-top"><strong>Images Count</strong></td>
                                        <td className="align-top">
                                            <div>5 Images</div>
                                        </td>
                                        <td className="align-top">
                                            <div>4 Images</div>
                                        </td>
                                        <td className="align-top">
                                            <div>9 Images</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-top"><strong>Brand and Chain</strong></td>
                                        <td className="align-top">Las Vegas Strip</td>
                                        <td className="align-top">Las Vegas Strip</td>
                                        <td className="align-top">Las Vegas Strip</td>
                                    </tr>
                                    <tr>
                                        <td className="align-top"><strong>Amenities</strong></td>
                                        <td className="align-top">
                                            <ul className="bulletList">
                                            {HotelCmpr.htlCmprObj.masterHotel.amenities && HotelCmpr.htlCmprObj.masterHotel.amenities.map((v, i) => ( 
                                                <li key={i}>{v.name}</li>
                                                ))
                                            }
                                            </ul>
                                        </td>
                                        <td className="align-top">
                                            <ul className="bulletList">
                                                <li>Air conditioning</li>
                                                <li>24-hour reception</li>
                                                <li>Hotel Safe</li>
                                                <li>Currency Exchange</li>
                                                <li>Cloakroom</li>
                                                <li>Cafe</li>
                                                <li>Shops</li>
                                            </ul>
                                        </td>
                                        <td className="align-top">
                                            <ul className="bulletList">
                                                <li>Air conditioning</li>
                                                <li>24-hour reception</li>
                                                <li>Hotel Safe</li>
                                                <li>Currency Exchange</li>
                                                <li>Cloakroom</li>
                                                <li>Cafe</li>
                                                <li>Shops</li>
                                                <li>Bar(s)</li>
                                                <li>Car Park</li>
                                                <li>Medical Assistance</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-top"><strong>Room Count</strong></td>
                                        <td className="align-top">40 Rooms</td>
                                        <td className="align-top">36 Rooms</td>
                                        <td className="align-top">55 Rooms</td>
                                    </tr>
                                    <tr>
                                        <td className="align-top"><strong>Location</strong></td>
                                        <td className="align-top">
                                            <div>
                                            {isLoaded && 
                                            <GoogleMap
                                            zoom={15}
                                            center={{lat: HotelCmpr.htlCmprObj.masterHotel.geocode.latitude, lng: HotelCmpr.htlCmprObj.masterHotel.geocode.longitute}}
                                            mapContainerStyle={{ width: "100%", height: "200px" }}>
                                                { activeMarker &&
                                                <InfoWindow onCloseClick={() => setActiveMarker(false)} position={{
                                                    lat: HotelCmpr.htlCmprObj.masterHotel.geocode.latitude, lng: HotelCmpr.htlCmprObj.masterHotel.geocode.longitute
                                                    }}
                                                    options={{pixelOffset: {width: 0, height: -35}}}>
                                                    <div className="blue"><strong>{HotelCmpr.htlCmprObj.masterHotel.name}</strong></div>
                                                </InfoWindow>
                                                }
                                                <Marker
                                                    position={{lat: HotelCmpr.htlCmprObj.masterHotel.geocode.latitude, lng: HotelCmpr.htlCmprObj.masterHotel.geocode.longitute}}
                                                    onClick={() => setActiveMarker(!activeMarker)}></Marker>
                                            </GoogleMap>
                                            }
                                            </div>
                                        </td>
                                        <td className="align-top">
                                            <div>
                                                {isLoaded ? 
                                                <>
                                                <GoogleMap
                                                zoom={12}
                                                center={{
                                                    lat: 51.093048,
                                                    lng: 6.842120,
                                                }}
                                                mapContainerStyle={{ width: "100%", height: "200px", borderRadius:'0.375rem' }}>
                                                </GoogleMap> 
                                                <div className="text-center"><Button variant="link" onClick={() => setShowMapModal(true)}>View Large</Button></div>
                                                </>
                                                :''
                                                }
                                            </div>
                                        </td>
                                        <td className="align-top">
                                            <div>
                                                {isLoaded ? 
                                                <>
                                                <GoogleMap
                                                zoom={12}
                                                center={{
                                                    lat: 51.093048,
                                                    lng: 6.842120,
                                                }}
                                                mapContainerStyle={{ width: "100%", height: "200px", borderRadius:'0.375rem' }}>
                                                </GoogleMap> 
                                                <div className="text-center"><Button variant="link" onClick={() => setShowMapModal(true)}>View Large</Button></div>
                                                </>
                                                :''
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <Modal show={showMapModal} onHide={() => setShowMapModal(false)} size="xl" centered>
                                <Modal.Header closeButton>
                                {/* <Modal.Title className="fn16">{t("CancellationPolicy")}</Modal.Title> */}
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        {isLoaded ? 
                                        <GoogleMap
                                        zoom={12}
                                        center={{
                                            lat: 51.093048,
                                            lng: 6.842120,
                                        }}
                                        mapContainerStyle={{ width: "100%", height: "500px"}}>
                                        </GoogleMap> :''
                                        }
                                    </div>
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div>
                </>
                :
                <div>Hotel Details Not Available</div>
            }
            </>
            :
            <div>Loading</div>
            }
            </>
            }

                </div>
            </div>
        </div>
    </DefaultLayout>
    </>
  )
}

export default HotelCompare

import ApiService from './api.service'

const HotelService = {
  getHotelSearch: async function (reqObj) {
    //console.log("reqObj", reqObj)
    const requestData = {
        method: 'post',
        data: reqObj,
        url: '/api/v1.0/masterhotels/search',
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      //console.log("response", response)
      return response.data
    } catch (error) {}
  },

  getHotelDetails: async function (reqObj) {
    //console.log("reqObj", reqObj)
    const requestData = {
        method: 'get',
        url: '/api/v1/masterhotels/'+reqObj,
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      //console.log("response", response)
      return response.data
    } catch (error) {}
  },

  getDsBoardHotelDtls: async function (reqObj) {
    const requestData = {
        method: 'get',
        url: '/api/v1/hotels/stats?clientCode='+reqObj,
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      //console.log("response", response)
      return response.data
    } catch (error) {}
  },

}
export default HotelService
export { HotelService }
import ApiService from './api.service'

const ContentService = {
  getCntQuality: async function (reqObj) {
    //console.log("reqObj", reqObj)
    const requestData = {
        method: 'get',
        //baseURL: process.env.REACT_APP_API_Hotel_Content_Portal_Api_Url,
        url: '/api/v1/content/quality?clientCode='+reqObj.clientCode+'&contentSources='+reqObj.contentSources,
    }
    try {
      //console.log("requestData", requestData)
      const response = await ApiService.customPromiseRequest(requestData)
      //console.log("response", response)
      return response.data
    } catch (error) {}
  },
}
export default ContentService
export { ContentService }
import React, { useState, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import DefaultLayout from '../../layouts/DefaultLayout';
import {Row, Col, InputGroup, Card, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import {doCntQuality} from "../../store/actions/content";
import inventory from '../../assets/images/inventory.png';
import mapEligible from '../../assets/images/mapEligible.png';
import mapping from '../../assets/images/mapping.png';
import dupHotel from '../../assets/images/dupHotel.png';
import quality from '../../assets/images/quality.png';

const ContentQuality = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([
    {name: 'Master', id: 'Master'},
    {name: 'EAN', id: 'EAN'},
    {name: 'PriceLine', id: 'PriceLine'},
    {name: 'Saber', id: 'Saber'},
  ]);

  const labelRef = useRef();
  const onChangeLecture = (value) => {
    let contentSources = []
    for (var k = 0; k < value.length; k++) {
      contentSources.push(value[k].id)
    }
    let cntQualityObj = {
      "clientCode": "Client001",
      "contentSources": contentSources.length ? contentSources.toString() : 'a',
    }
    dispatch(doCntQuality(cntQualityObj));
    //multiselectRef.current.focus();
    //console.log(labelRef)
    //multiselectRef.current.onCloseOptionList();
    
  };
  

  

  const CntQualityResult = useSelector((state) => state.cntQualityList);
   
  return (
    <>
    <DefaultLayout>
        <div className="px-md-4 px-3 py-4">
            <div className="pageHeading px-2"><h2>Content Quality</h2></div>
            
            <Row className="mb-3">
              <Col md>
                <Card className="border-0 rounded-3 mb-3 bgDiv bg1">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 className="mb-0">68%</h4>
                        <div className="lead fn15">Mapping %</div>
                      </div>
                      <div className="text-end">
                        <div className='roundIcon'><FontAwesomeIcon icon={regular('map')} /></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md>
                <Card className="border-0 rounded-3 mb-3 bgDiv bg2">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 className="mb-0">Missing Info</h4>
                        <div className="lead fn15">Reason for Un-Mapp</div>
                      </div>
                      <div className="text-end">
                        <div className='roundIcon'><FontAwesomeIcon icon={regular('circle-question')} /></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md>
                <Card className="border-0 rounded-3 mb-3 bgDiv bg3">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 className="mb-0">24%</h4>
                        <div className="lead fn15">Duplicate Hotels %</div>
                      </div>
                      <div className="text-end">
                        <div className='roundIcon'><FontAwesomeIcon icon={regular('copy')} /></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md>
                <Card className="border-0 rounded-3 mb-3 bgDiv bg4">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 className="mb-0">430</h4>
                        <div className="lead fn15">Exclusive Property Count</div>
                      </div>
                      <div className="text-end">
                        <div className='roundIcon'><FontAwesomeIcon icon={solid('hotel')} /></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              
            </Row>

            <div className="bg-white shadow rounded mb-4">
              <div className="px-3 py-3">
                <Row className="gx-3">
                  <Col lg={6} className="mb-3">
                    <label className="ms-1 mb-1">Select Content Source</label>
                    <InputGroup className="shadow-sm rounded multiSelectDiv">
                      <Multiselect className="rounded-0 rounded-start form-control form-control-lg fn15 shadow-sm"
                        closeOnSelect={true}
                        options={options} 
                        avoidHighlightFirstOption={true}
                        displayValue="name"
                        placeholder=""
                        onSelect={(value) => {onChangeLecture(value);labelRef.current.focus()}}
                        onRemove={(value) => onChangeLecture(value)}
                        />
                        <Button variant="outline-light shadow-sm bg-white" ref={labelRef}><FontAwesomeIcon icon={solid('magnifying-glass')} className="text-dark" /></Button>
                      {/* <InputGroup.Text className="bg-white fn14 shadow-sm"><FontAwesomeIcon icon={solid('magnifying-glass')} /></InputGroup.Text> */}
                    </InputGroup>
                  </Col>
                </Row>
              </div>
            </div>

            {CntQualityResult &&
            <>
            {CntQualityResult.cntQualityObj ?
            <>
            { CntQualityResult.cntQualityObj.contentQualityStats && CntQualityResult.cntQualityObj.contentQualityStats.length > 0 && 
            <>
              {CntQualityResult.cntQualityObj.contentQualityStats.map((v, i) => ( 
              <div className="mb-4 px-4 py-3 border-bottom" key={i}>
                <h3 className="mb-4 text-center text-primary">{v.contentSource}</h3>
                <Row>
                  <Col md className="mb-3">
                    <Card className="border-0 rounded-3 h-100 shadow text-center">
                      <Card.Body>
                        <div className="mb-3"><img src={inventory} alt="inventory" /></div>
                        <h4>{v.totalHotels}</h4>
                        <div className="lead fn16">Total Inventory</div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md className="mb-3">
                    <Card className="border-0 rounded-3 h-100 shadow text-center">
                      <Card.Body>
                        <div className="mb-3"><img src={mapEligible} alt="mapEligible" /></div>
                        <h4>{v.mappingEligibleHotels}</h4>
                        <div className="lead fn16">Mapping Eligible Inventory</div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md className="mb-3">
                    <Card className="border-0 rounded-3 h-100 shadow text-center">
                      <Card.Body>
                        <div className="mb-3"><img src={mapping} alt="mapping" /></div>
                        <h4>{v.hotelsMappingPercentage}%</h4>
                        <div className="lead fn16">Mapping %</div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md className="mb-3">
                    <Card className="border-0 rounded-3 h-100 shadow text-center">
                      <Card.Body>
                        <div className="mb-3"><img src={dupHotel} alt="dupHotel" /></div>
                        <h5>{v.duplicateHotelsPercentage}%</h5>
                        <div className="lead fn16">Duplicate Hotel %</div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md className="mb-3">
                    <Card className="border-0 rounded-3 h-100 shadow text-center">
                      <Card.Body>
                        <div className="mb-3"><img src={quality} alt="quality" /></div>
                        <h5>{v.ciQualityRating}</h5>
                        <div className="lead fn16">Quality Rating</div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {v.contentIssueSummary &&
                <>
                {v.contentIssueSummary.invalidHotels && v.contentIssueSummary.missingGeocode && v.contentIssueSummary.missingAddress && v.contentIssueSummary.missingCityCode && v.contentIssueSummary.missingCountryCode && v.contentIssueSummary.missingZipCode &&
                  <h6 className="mt-2 mb-1 text-primary">More Details</h6>
                }
                <Row className="gx-2 row-cols-1 row-cols-lg-4">
                  {v.contentIssueSummary.invalidHotels &&
                    <Col>
                      <div className="fs-6 my-1">Invalid Hotels: <strong>{v.contentIssueSummary.invalidHotels}</strong></div>
                    </Col>
                  }
                  
                  {v.contentIssueSummary.missingGeocode &&
                    <Col>
                      <div className="fs-6 my-1">Missing Geo Code: <strong>{v.contentIssueSummary.missingGeocode}</strong></div>
                    </Col>
                  }
                  
                  {v.contentIssueSummary.missingAddress &&
                    <Col>
                      <div className="fs-6 my-1">Missing Address: <strong>{v.contentIssueSummary.missingAddress}</strong></div>
                    </Col>
                  }

                  {v.contentIssueSummary.missingCityCode &&
                    <Col>
                      <div className="fs-6 my-1">Missing City Code: <strong>{v.contentIssueSummary.missingCityCode}</strong></div>
                    </Col>
                  }
                  
                  {v.contentIssueSummary.missingCountryCode &&
                    <Col>
                      <div className="fs-6 my-1">Missing Country Code: <strong>{v.contentIssueSummary.missingCountryCode}</strong></div>
                    </Col>
                  }
                  
                  {v.contentIssueSummary.missingZipCode &&
                    <Col>
                      <div className="fs-6 my-1">Missing Zip Code: <strong>{v.contentIssueSummary.missingZipCode}</strong></div>
                    </Col>
                  }
                </Row>
                </>
                }
              </div>
              ))
              }
            </>
            }
            </>
            :
            <div className="bg-white shadow-sm rounded">
              <div className="p-5 justify-content-center w-100 d-flex align-items-center"> 
                <Spinner animation="border" variant="primary" />
                <span className="fs-5">&nbsp; Loading...</span>
              </div>
            </div>
            }
            </>
            }

        </div>
    </DefaultLayout>
    </>
  )
}

export default ContentQuality

import React, { useState } from "react";
import {Container, Navbar, Nav, Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import userDefault from '../../assets/images/user.jpg';
import logo from '../../assets/images/logo.png';

const Header = ({update}) => {
  const [toggleDiv, setToggleDiv] = useState(true);

  const toggleBtn = () => {
    update(!toggleDiv)
    return setToggleDiv(!toggleDiv)
	};

  return(
  <header>
    <Navbar className="topnav justify-content-between justify-content-sm-start bg-white">
      <Container fluid>
        <div className={toggleDiv ? 'toggleSmall' : 'toggleBig'}>
            <Button variant="link" className="btn-icon border"  
              onClick={() => toggleBtn()}>
              {toggleDiv ? 
                <FontAwesomeIcon icon={solid('bars')} />
                : 
                <FontAwesomeIcon icon={solid('xmark')} />
              }
          </Button>
        </div>
      
        <div className={"align-items-center ms-auto d-md-none d-block " + (toggleDiv ? '' : 'd-noneSmall')}><img src={logo} alt="Code Impacts" /></div>
      
        <Nav className={"align-items-center ms-auto " + (toggleDiv ? '': 'd-noneSmall')}>
          <div className="position-relative">
            <Dropdown>
              <Dropdown.Toggle as="div" className="d-flex align-items-center cursor-pointer">
                  <div className="me-2 fn14 d-md-block d-none"><strong>Welcome,</strong> Rahul Pawar</div>
                  <span className="btn btn-light btn-icon d-flex">
                    <img src={userDefault} alt="" />
                  </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-end">
                  <Dropdown.Item as="div" bsPrefix="px-3 fn14 text-center">
                    <div><strong>Rahul Pawar</strong></div>
                    <div>mohitpawar01@gmail.com</div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-3">Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            </div>
        </Nav>
      </Container>
    </Navbar>
  </header>
  );
}
export default Header;
import {ContentService} from "../../services/content.service";
import {RESET_CNTQUALITY_RES, SET_CNTQUALITYRES } from "../types/contentTypes";

export const doCntQuality = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_CNTQUALITY_RES})
            const response = await ContentService.getCntQuality(reqObj);
            dispatch({ type: SET_CNTQUALITYRES, payload: response })
            
        } catch (err) {
            console.log("Content Quality Error", err);
        }
    }
}
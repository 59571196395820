import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import DefaultLayout from '../../layouts/DefaultLayout';
import {Row, Col, Card, Form, Spinner} from 'react-bootstrap';
import logo from '../../assets/images/logo.png';
import chart1 from '../../assets/images/chart1.jpg';
import {dsBoardHotelDtls} from "../../store/actions/hotel";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from "recharts";
import {doCntSync} from "../../store/actions/contentSync";

const Dashboard = () => {
    const dispatch = useDispatch();
    const [graphObj, setGraphObj] = useState([])
    const DsBoardHtl = useSelector((state) => state.dsBoardHotelDtls);
    const CntSyncList = useSelector((state) => state.cntSyncList);

    useEffect(()=>{
        dispatch(dsBoardHotelDtls('Client001'));
        dispatch(doCntSync({
            "clientCode":"client01",
        }));
    },[dispatch]);

    useEffect(()=>{
        creatGraphObj()
    },[DsBoardHtl]);

      const creatGraphObj = () =>{
        //console.log("DsBoardHtl", DsBoardHtl)
        if(DsBoardHtl && DsBoardHtl.dsBoardDtlObj && DsBoardHtl.dsBoardDtlObj.hotelContentStats && DsBoardHtl.dsBoardDtlObj.hotelContentStats.multiLingual){
            let obj = []
            DsBoardHtl.dsBoardDtlObj.hotelContentStats.multiLingual.forEach((v, i) => {
                let cntObj = {
                    contentSource: v.contentSource,
                }
                if (v.stats) {
                    v.stats.forEach((val) => {
                        if(val.name==="en-US"){
                            cntObj.enUS = val.hotelsCount
                        }
                        if(val.name==="es-ES"){
                            cntObj.esES = val.hotelsCount
                        }
                        if(val.name==="es-MX"){
                            cntObj.esMX = val.hotelsCount
                        }
                    })
                }
                obj.push(cntObj)
            })
            //console.log("obj", obj)
            setGraphObj(obj)
        }
      }

  return (
    <>
    <DefaultLayout>
    <div className="px-md-4 px-3 py-4">
        <div className="pageHeading"><h2>Dashboard</h2></div>
        <Row className="mt-4">
            <Col md={9}>
                {DsBoardHtl && DsBoardHtl.dsBoardDtlObj && DsBoardHtl.dsBoardDtlObj.hotelContentStats ?
                <div className="mb-4">
                    <Row>
                        <Col md={4}>
                            <Card className="border-0 rounded-3 mb-3 shadow-sm text-info">
                                <Card.Body>
                                    <div className="lead fn18 mb-2">Hotels</div>
                                    <h3>{DsBoardHtl.dsBoardDtlObj.hotelContentStats.totalHotels}</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-0 rounded-3 mb-3 shadow-sm text-pink">
                                <Card.Body>
                                    <div className="lead fn18 mb-2">Unique Hotels</div>
                                    <h3>{DsBoardHtl.dsBoardDtlObj.hotelContentStats.uniqueHotels}</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-0 rounded-3 mb-3 shadow-sm text-success">
                                <Card.Body>
                                    <div className="lead fn18 mb-2">Active Hotels</div>
                                    <h3>{DsBoardHtl.dsBoardDtlObj.hotelContentStats.activeHotels}</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                : 
                ''
                }
                
                {graphObj &&
                <div className="bg-white shadow-sm rounded p-3 mb-4 fn12">
                    <h3 className="fs-5 mb-3">Multi-Lingual Properties</h3>
                    <ResponsiveContainer width='100%' aspect={4.0/2.0}>
                        <BarChart data={graphObj}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="contentSource" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="enUS" name="en-US" fill="#2b6eb5" barSize={25} />
                            <Bar dataKey="esES" name="es-ES" fill="#ffca41" barSize={25} />
                            <Bar dataKey="esMX" name="es-MX" fill="#ff6161" barSize={25} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                }
                

                <div className="bg-white shadow-sm rounded px-4 py-3 mb-4">
                    {CntSyncList && CntSyncList.cntSyncObj ? 
                    <div>
                        <h3 className="fs-5 mb-3">Recent Content Sync Status</h3>
                        {CntSyncList.cntSyncObj.hotelContentSyncStats.length > 0 ?
                        <div className="table-responsive">
                            <table className="table table-striped align-middle text-center">
                                <thead>
                                    <tr className="text-uppercase">
                                        <th scope="col" className="text-start">Execution Id</th>
                                        <th scope="col" className="text-start">Date</th>
                                        <th scope="col">Total Properties</th>
                                        <th scope="col">Delivery Mode</th>
                                        <th scope="col">Content Source</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CntSyncList.cntSyncObj.hotelContentSyncStats.map((v, i) => ( 
                                    <tr key={i}>
                                        <td className="text-start text-nowrap">{v.executionId}</td>
                                        <td className="text-start text-nowrap">2 Aug 2022</td>
                                        <td>{v.totalHotels}</td>
                                        <td>{v.deliveryMode}</td>
                                        <td>{v.contentSource}</td>
                                        <td>
                                        {v.status === 'complete' &&
                                            <span className="bg-success rounded-pill text-white px-3 py-1 fn12">Completed</span>
                                        }

                                        {v.status === 'pending' &&
                                            <span className="bg-warning rounded-pill text-white px-3 py-1 fn12">Pending</span>
                                        }

                                        {v.status === 'reject' &&
                                            <span className="bg-danger rounded-pill text-white px-3 py-1 fn12">Reject</span>
                                        }
                                        
                                        </td>
                                    </tr>
                                    ))
                                    }
                                    {/* <tr>
                                        <td className="text-start">Exe152</td>
                                        <td className="text-start text-nowrap">10 July 2022</td>
                                        <td>52,000</td>
                                        <td>API</td>
                                        <td>Example2</td>
                                        <td><span className="badge bg-warning rounded-pill">&nbsp; Pending &nbsp;</span></td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">Exe103</td>
                                        <td className="text-start text-nowrap">5 March 2022</td>
                                        <td>51,250</td>
                                        <td>API</td>
                                        <td>Example3</td>
                                        <td><span className="badge bg-danger rounded-pill">&nbsp; &nbsp; Reject &nbsp; &nbsp;</span></td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="text-center">
                          <img src={require('../../assets/images/noResult.png')} alt="No Result Found" />
                          <div className="fs-4 mt-2">Result Not Found</div>
                        </div>
                        }
                    </div>
                    :
                    <div className="p-5 justify-content-center w-100 d-flex align-items-center"> 
                      <Spinner animation="border" variant="primary" />
                      <span className="fs-5">&nbsp; Loading...</span>
                    </div>
                    }

                </div>
                
            </Col>
            <Col md={3}>
                <div className="bg-white shadow-sm rounded p-3 mb-4 fn12">
                    <h3 className="fs-6 mb-1">Support Statistics</h3>
                    <div className="text-muted">From 1-15 June, 2022</div>
                    <div className="text-center mt-1"><img src={chart1} alt="chart1" /></div>
                </div>
                <div className="bg-white shadow-sm rounded p-3 mb-4">
                    <Row className="gx-2">
                        <Col xs={7}>
                            <h3 className="fs-6 mb-3">Support Activity</h3>
                        </Col>
                        <Col xs={5}>
                        <Form.Select size="sm" className="fn12">
                            <option>Monthly</option>
                            <option>Yearly</option>
                        </Form.Select>
                            
                        </Col>
                    </Row>
                    
                    <div className="mb-3 pb-2 border-bottom fn12">
                        <div className="d-flex align-items-center">
                            <div className="me-2 mb-1">
                                <div className="rounded-circle d-flex align-items-center overflow-hidden" style={{width:"45px",height:"45px"}}><img src="https://demos.wrappixel.com/free-admin-templates/bootstrap/flexy-bootstrap-free/assets/images/users/4.jpg" alt="user" /></div>
                            </div>
                            <div className="w-100">
                                <h6 className="fn14 m-0">James Anderson</h6>
                                <div className="text-muted mb-1">April 14, 2021</div>
                            </div>
                        </div>
                        <div className="mb-1 text-muted">Information provided to client.</div>
                        <div className="text-end">Closing the ticket.</div>
                    </div>
                    <div className="mb-3 pb-2 border-bottom fn12">
                        <div className="d-flex align-items-center">
                            <div className="me-2 mb-1">
                                <div className="rounded-circle d-flex align-items-center overflow-hidden" style={{width:"45px",height:"45px"}}><img src="https://demos.wrappixel.com/free-admin-templates/bootstrap/flexy-bootstrap-free/assets/images/users/5.jpg" alt="user" /></div>
                            </div>
                            <div className="w-100">
                                <h6 className="fn14 m-0">James Anderson</h6>
                                <div className="text-muted mb-1">April 14, 2021</div>
                            </div>
                        </div>
                        <div className="mb-1 text-muted">Information provided to client.</div>
                        <div className="text-end">Supplier data is successfully.</div>
                    </div>
                    <div className="mb-3 pb-2 border-bottom fn12">
                        <div className="d-flex align-items-center">
                            <div className="me-2 mb-1">
                                <div className="rounded-circle border d-flex align-items-center overflow-hidden" style={{width:"45px",height:"45px"}}><img src={logo} alt="user" /></div>
                            </div>
                            <div className="w-100">
                                <h6 className="fn14 m-0">James Anderson</h6>
                                <div className="text-muted mb-1">April 14, 2021</div>
                            </div>
                        </div>
                        <div className="mb-1 text-muted">Information provided to client.</div>
                        <div className="text-end">Expected the next update in 8 hours</div>
                    </div>
                    <div className="mb-3 pb-2 border-bottom fn12">
                        <div className="d-flex align-items-center">
                            <div className="me-2 mb-1">
                                <div className="rounded-circle d-flex align-items-center overflow-hidden" style={{width:"45px",height:"45px"}}><img src="https://demos.wrappixel.com/free-admin-templates/bootstrap/flexy-bootstrap-free/assets/images/users/5.jpg" alt="user" /></div>
                            </div>
                            <div className="w-100">
                                <h6 className="fn14 m-0">James Anderson</h6>
                                <div className="text-muted mb-1">April 14, 2021</div>
                            </div>
                        </div>
                        <div className="mb-1 text-muted">Information provided to client.</div>
                        <div className="text-end">Closing the ticket</div>
                    </div>
                </div>
            </Col>
        </Row>
  
    </div>
    </DefaultLayout>
    </>
  )
}

export default Dashboard

import React, { useEffect, useState, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import DefaultLayout from '../../layouts/DefaultLayout';
import {Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import {doCntSync} from "../../store/actions/contentSync";

const ContentSync = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(doCntSync({
      "clientCode":"client01",
    }));
  },[dispatch]);

  const CntSyncList = useSelector((state) => state.cntSyncList);
  
  const [chkIn, setChkIn] = useState(new Date());
  const [chkOut, setChkOut] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const endRef = useRef();
  const disableTo = () => {
    setChkOut(new Date(new Date(chkIn).setDate(new Date(chkIn).getDate() + 1)))
    endRef.current.setOpen(true);    
  };

  return (
    <>
    <DefaultLayout>
        <div className="px-md-4 px-3 py-4">
          <div className="pageHeading px-2"><h2>Content Sync</h2></div>
          
          <div className="bg-white shadow rounded mb-4">
            <div className="px-3 py-3">
              <Row className="gx-3">
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label className="ms-1 mb-1">Select Content Source</Form.Label>
                    <Form.Select className="shadow-sm fn14" size="lg">
                      <option>--Select--</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label className="ms-1 mb-1">Select Content Culture</Form.Label>
                    <Form.Select className="shadow-sm fn14" size="lg">
                      <option>--Select--</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={2}>
                  <Form.Group className="mb-3">
                    <Form.Label className="ms-1 mb-1">Select Status</Form.Label>
                    <Form.Select className="shadow-sm fn14" size="lg">
                      <option>--Select--</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                  
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <div className="fifthcol designCalendar">
                      <Form.Label className="ms-1 mb-1">From</Form.Label>
                      <DatePicker className="form-control form-control-lg shadow-sm fn14" dateFormat="dd MMM yyyy" selected={chkIn} minDate={new Date()} onChange={ (date) => setChkIn(date)} onCalendarClose={disableTo} />
                    </div>
                    <div className="fifthcol designCalendar">
                      <Form.Label className="ms-1 mb-1">To</Form.Label>
                      <DatePicker className="form-control form-control-lg shadow-sm fn14" dateFormat="dd MMM yyyy" selected={chkOut} minDate={new Date(new Date(chkIn).setDate(new Date(chkIn).getDate() + 1))} onChange={ (date) => setChkOut(date) } ref={endRef} />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={1} className="text-end">
                <Form.Label className="ms-1 mb-1">&nbsp;</Form.Label>
                  <Button variant="primary" className="px-4">Filter</Button>
                </Col>
              </Row>
              
             
            </div>
          </div>
         
            <div className="bg-white shadow-sm rounded">
              {CntSyncList && CntSyncList.cntSyncObj ? 
              <div className="p-3">
                <h5 className="mb-3">Content Source List</h5>
                
                {CntSyncList.cntSyncObj.hotelContentSyncStats.length > 0 ? 
                <div className="table-responsive">
                  <table className="table table-striped text-center align-middle">
                    <thead>
                      <tr className="align-middle text-uppercase">
                        <th scope="col" className="lh-sm text-start">Content Source</th>
                        <th scope="col" className="lh-sm">Type</th>
                        <th scope="col" className="lh-sm">Culture</th>
                        <th scope="col" className="lh-sm">Total Property</th>
                        <th scope="col" className="lh-sm">New</th>
                        <th scope="col" className="lh-sm">Update</th>
                        <th scope="col" className="lh-sm">Delete</th>
                        <th scope="col" className="lh-sm">Recycle</th>
                        <th scope="col" className="lh-sm">No Change</th>
                        <th scope="col" className="lh-sm">Status</th>
                        <th scope="col" className="lh-sm">Time Taken</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CntSyncList.cntSyncObj.hotelContentSyncStats.map((v, i) => ( 
                      <tr key={i}>
                        <td className="text-start"><strong>{v.contentSource}</strong><br /> <span className="text-muted fn13">03/05/2022</span></td>
                        <td>Full</td>
                        <td>{v.culture}</td>
                        <td>{v.totalHotels}</td>
                        <td>{v.insertedHotels}</td>
                        <td>{v.updatedHotels}</td>
                        <td>{v.deletedHotels}</td>
                        <td>{v.recycledHotels}</td>
                        <td>1800</td>
                        <td>
                          {v.status === 'complete' &&
                            <span className="bg-success rounded-pill text-white px-3 py-1 fn12">Completed</span>
                          }

                          {v.status === 'pending' &&
                            <span className="bg-warning rounded-pill text-white px-3 py-1 fn12">Pending</span>
                          }

                          {v.status === 'reject' &&
                            <span className="bg-danger rounded-pill text-white px-3 py-1 fn12">Reject</span>
                          }
                          
                        </td>
                        <td>2 Hrs</td>
                      </tr>
                      ))
                      }
                    </tbody>
                  </table>
                </div>
                :
                <div className="text-center">
                  <img src={require('../../assets/images/noResult.png')} alt="No Result Found" />
                  <div className="fs-4 mt-2">Result Not Found</div>
                </div>
                }
              </div>
              :
              <div className="p-5 justify-content-center w-100 d-flex align-items-center"> 
                <Spinner animation="border" variant="primary" />
                <span className="fs-5">&nbsp; Loading...</span>
              </div>
              }
            </div>
            

        </div>
    </DefaultLayout>
    </>
  )
}

export default ContentSync

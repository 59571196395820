import {RESET_CNTSYNC_RES, SET_CNTSYNCRES } from "../types/contentSyncTypes";
  
const intialState = {
  cntSyncObj:null,
};

export const cntSyncListReducer = (state = intialState.cntSyncObj, { type, payload }) => {
  switch (type) {
    case RESET_CNTSYNC_RES:
      return { ...state, cntSyncObj: null};
    case SET_CNTSYNCRES:
      return { ...state, cntSyncObj: payload };
    
    default:
      return state;
  }
};



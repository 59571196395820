import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Dashboard from './pages/dashboard/Dashboard';
import HotelSearch from './pages/hotel/HotelSearch';
import HotelDetails from './pages/hotel/HotelDetails';
import HotelCompare from './pages/hotel/HotelCompare';
import ContentQuality from './pages/contentQuality/ContentQuality';
import ContentSync from './pages/contentSync/ContentSync';
import ClientManagement from './pages/clientManagement/ClientManagement';
import IssueResolution from './pages/issueResolution/IssueResolution';
import ClientRequest from './pages/clientRequest/ClientRequest';
import Settings from './pages/settings/Settings';
import Support from './pages/support/Support';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/hotelSearch" element={<HotelSearch />} />
        <Route path="/hotelDetails" element={<HotelDetails />} />
        <Route path="/hotelCompare" element={<HotelCompare />} />
        <Route path="/contentQuality" element={<ContentQuality />} />
        <Route path="/contentSync" element={<ContentSync />} />
        <Route path="/clientManagement" element={<ClientManagement />} />
        <Route path="/issueResolution" element={<IssueResolution />} />
        <Route path="/issueResolution" element={<IssueResolution />} />
        <Route path="/clientRequest" element={<ClientRequest />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/support" element={<Support />} />
      </Routes>
  </BrowserRouter>
  </>
  );
}

export default App;

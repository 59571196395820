import {NavLink } from "react-router-dom";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import logoS from '../../assets/images/logoS.png';
import logo from '../../assets/images/logo.png';

const Sidebar = () => {
  return(
    <div className="leftSidebar bg-primary">
      <div className="p-2 bg-white">
        <NavLink to="/dashboard"><img src={logoS} alt="Code Imapacts" className="logoSmall" />
        <img src={logo} alt="Code Imapacts" className="logoBig" /></NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/dashboard" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Dashboard</Tooltip>}><FontAwesomeIcon icon={solid('vector-square')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Dashboard</span>
        </NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/hotelSearch" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Hotel Search</Tooltip>}><FontAwesomeIcon icon={solid('magnifying-glass')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Hotel Search</span>
        </NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/hotelCompare" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Hotel Compare</Tooltip>}><FontAwesomeIcon icon={solid('repeat')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Hotel Compare</span>
        </NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/contentQuality" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Content Quality</Tooltip>}><FontAwesomeIcon icon={solid('ranking-star')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Content Quality</span>
        </NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/contentSync" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Content Sync</Tooltip>}><FontAwesomeIcon icon={solid('arrows-rotate')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Content Sync</span>
        </NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/clientManagement" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Client Management</Tooltip>}><FontAwesomeIcon icon={solid('user-cog')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Client Management</span>
        </NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/issueResolution" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Issue Resolution</Tooltip>}><FontAwesomeIcon icon={solid('exclamation-triangle')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Issue Resolution</span>
        </NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/clientRequest" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Request</Tooltip>}><FontAwesomeIcon icon={regular('message')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Request</span>
        </NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/settings" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Settings</Tooltip>}><FontAwesomeIcon icon={solid('gear')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Settings</span>
        </NavLink>
      </div>
      <div className="leftLink">
        <NavLink to="/support" className="nav-link">
          <span className="sideNavIcon me-2"><OverlayTrigger placement="right" overlay={<Tooltip>Support</Tooltip>}><FontAwesomeIcon icon={solid('headset')} /></OverlayTrigger></span> 
          <span className="sideNavTxt">Support</span>
        </NavLink>
      </div>
    </div>
  );
}
export default Sidebar;
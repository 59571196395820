import React, { useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';


const IssueResolution = () => {
   
  return (
    <>
    <DefaultLayout>
        <div className="px-md-4 px-3 py-4">
            <div className="pageHeading px-2"><h2>Issue Resolution</h2></div>
            <div className="bg-white shadow-sm rounded p-3">
              Issue Resolution
            </div>
        </div>
    </DefaultLayout>
    </>
  )
}

export default IssueResolution

import axios from 'axios'
//import TokenService from './storage.service'
//import { store } from '../store'
const ApiService = {

  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,
  
  init(baseURL) {
    axios.defaults.baseURL = baseURL
  },

  // setHeader () {
  //   axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`
  // },

  removeHeader () {
    axios.defaults.headers.common = {}
  },

  get (resource) {
    return axios.get(resource)
  },

  post (resource, data) {
    return axios.post(resource, data)
  },

  put (resource, data) {
    return axios.put(resource, data)
  },

  delete (resource) {
    return axios.delete(resource)
  },

  /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
    customRequest(data) {
    return axios(data)
    },
    customPromiseRequest(data) {
        return new Promise(resolve => {
            resolve(axios(data))
        })
    },
  unmount401Interceptor () {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor)
  }
}

export default ApiService

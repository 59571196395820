import ApiService from './api.service'

const ContentSyncService = {
  getCntSync: async function (reqObj) {
    const requestData = {
        method: 'post',
        url: '/api/v1.0/hotels/stats/sync',
        data: reqObj,
    }
    try {
      console.log("requestData", requestData)
      const response = await ApiService.customPromiseRequest(requestData)
      console.log("response", response)
      return response.data
    } catch (error) {}
  },
}
export default ContentSyncService
export { ContentSyncService }
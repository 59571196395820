import React, {useEffect, useState } from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import DefaultLayout from '../../layouts/DefaultLayout';
import {Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import {getHotelDtls} from "../../store/actions/hotel";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";

const HotelDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const qry = useLocation(); 

    useEffect(()=>{
        dispatch(getHotelDtls(qry.state.aqHotelId));
    },[dispatch]);

    const HotelDtls = useSelector((state) => state.hotelDtls);
    //console.log("HotelDtls", HotelDtls)
    const [activeMarker, setActiveMarker] = useState(true);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPAPIKEY
    })
    
    const images = [
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/1.jpg", title: "1/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/2.jpg", title: "2/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/3.jpg", title: "3/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/4.jpg", title: "4/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/5.jpg", title: "5/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/6.jpg", title: "6/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/7.jpg", title: "7/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/8.jpg", title: "8/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/9.jpg", title: "9/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/10.jpg", title: "10/11",},
        {src: "https://naver.github.io/egjs-infinitegrid/assets/image/11.jpg", title: "11/11",},
     ];

     const [index, setIndex] = useState(-1);
     const goBack = () => {
		navigate(-1);
	}

    const cmprHotel = (aqHotelId) => {
        navigate(`/hotelCompare`,{state:{'aqHotelId':aqHotelId}})
    }

  return (
    <>
    <DefaultLayout>
        <div className="px-md-4 px-3 py-4">
            <div className="pageHeading px-2"><h2>Hotel Details</h2></div>
            <div className="bg-white shadow-sm rounded">
                <div className="d-flex justify-content-between p-3 pb-2">
                    <div><Button variant="link" size="sm" className="fn16 p-0" onClick={goBack}><FontAwesomeIcon icon={solid('long-arrow-alt-left')} /> See all properties</Button></div>
                    <div>
                    {HotelDtls &&
                        <>
                        { HotelDtls.htlDtlObj && 
                        <>
                        { HotelDtls.htlDtlObj.masterHotel && 
                            <Button variant="primary" size="sm" onClick={()=>cmprHotel(qry.state.aqHotelId)}>Compare Hotels</Button>
                        }
                        </>
                        }
                        </>
                    }
                    </div>
                </div>

                {HotelDtls &&
                <>
                { HotelDtls.htlDtlObj ? 
                <>
                { HotelDtls.htlDtlObj.masterHotel ? 
                <>
                <div className="position-relative mb-3">
                <Lightbox
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    slides={images} 
                    plugins={[Captions]} />
                    <Row className="g-1 ggallery">
                        <Col>
                            <div onClick={() => setIndex(0)} className="gImage h-100" style={{backgroundImage:`url('https://naver.github.io/egjs-infinitegrid/assets/image/1.jpg')`}}></div>
                        </Col>
                        <Col>
                            <Row className="g-1 h-100">
                                <Col md={12}><div onClick={() => setIndex(1)} className="gImage h-100" style={{backgroundImage:`url('https://naver.github.io/egjs-infinitegrid/assets/image/2.jpg')`}}></div></Col>
                                <Col md={12}><div onClick={() => setIndex(2)} className="gImage h-100" style={{backgroundImage:`url('https://naver.github.io/egjs-infinitegrid/assets/image/3.jpg')`}}></div></Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="g-1 h-100">
                                <Col md="12"><div onClick={() => setIndex(3)} className="gImage h-100" style={{backgroundImage:`url('https://naver.github.io/egjs-infinitegrid/assets/image/4.jpg')`}}></div></Col>
                                <Col md="12">
                                    <Row className="gx-1 h-100">
                                        <Col><div onClick={() => setIndex(4)} className="gImage h-100" style={{backgroundImage:`url('https://naver.github.io/egjs-infinitegrid/assets/image/5.jpg')`}}></div></Col>
                                        <Col><div onClick={() => setIndex(5)} className="gImage h-100" style={{backgroundImage:`url('https://naver.github.io/egjs-infinitegrid/assets/image/6.jpg')`}}></div></Col>
                                    </Row>
                                </Col>
                            </Row> 
                        </Col>
                    </Row>
                    <div style={{backgroundColor:'rgba(0,0,0,0.75)', position:'absolute', right:'5px', bottom:'10px',color:'#FFF', padding:'5px 10px'}}><FontAwesomeIcon icon={regular('image')} /> &nbsp;38+</div>
                </div>
                <div>
                    <Tabs defaultActiveKey="overview" className="px-2 tabBorder fs-6">
                        <Tab eventKey="overview" title="Overview">
                            <div className="p-3">
                                <div className="d-md-flex justify-content-between">
                                    <div>
                                        <h2 className="fn18 mb-1">{HotelDtls.htlDtlObj.masterHotel.name}
                                        {HotelDtls.htlDtlObj.masterHotel.rating &&
                                        <>
                                            {HotelDtls.htlDtlObj.masterHotel.rating.starRating &&
                                                <span className="d-inline-flex ms-1">
                                                {
                                                Array.apply(null, { length:parseInt(HotelDtls.htlDtlObj.masterHotel.rating.starRating)}).map((e, i) => (
                                                    <FontAwesomeIcon key={i} icon={solid('star')} className="stargold fn15" />
                                                ))
                                                }
                                                </span>
                                            }

                                            {HotelDtls.htlDtlObj.masterHotel.rating.tripAdvisorRating &&
                                            <>&nbsp;
                                            { !(HotelDtls.htlDtlObj.masterHotel.rating.tripAdvisorRating.toString().indexOf('.') > -1) ?
                                            <img style={{maxWidth:"100px"}} src={`http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${HotelDtls.htlDtlObj.masterHotel.rating.tripAdvisorRating}.0-13387-4.png`} alt="tripadvisor" />
                                            :
                                            <img style={{maxWidth:"100px"}} src={`http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${HotelDtls.htlDtlObj.masterHotel.rating.tripAdvisorRating}-13387-4.png`} alt="tripadvisor" />
                                            }
                                            </>
                                            }
                                        </>
                                        }
                                        </h2>
                                        <div className="text-muted mb-2 fn14">
                                        {HotelDtls.htlDtlObj.masterHotel.address && 
                                            <>
                                            {HotelDtls.htlDtlObj.masterHotel.address.line1 && HotelDtls.htlDtlObj.masterHotel.address.line1} {HotelDtls.htlDtlObj.masterHotel.address.line2 && ','+HotelDtls.htlDtlObj.masterHotel.address.line2} {HotelDtls.htlDtlObj.masterHotel.address.cityName && ','+HotelDtls.htlDtlObj.masterHotel.address.cityName} {HotelDtls.htlDtlObj.masterHotel.address.countryName && ','+HotelDtls.htlDtlObj.masterHotel.address.countryName}
                                            </>
                                        }
                                        </div>
                                    </div>
                                    <div className="semibold">Master Hotel Id - {HotelDtls.htlDtlObj.masterHotel.masterHotelId && HotelDtls.htlDtlObj.masterHotel.masterHotelId}</div>
                                </div>
                                <div className="text-muted fw-light">
                                    {HotelDtls.htlDtlObj.masterHotel.description && 
                                    <p>{HotelDtls.htlDtlObj.masterHotel.description}</p>
                                    }
                                </div>
                                <h3 className="fn15 mb-3">Popular Amenities</h3>
                                <ul className="list-inline fn15 mb-4">
                                    {HotelDtls.htlDtlObj.masterHotel.amenities && HotelDtls.htlDtlObj.masterHotel.amenities.map((v, i) => ( 
                                        <>
                                        {v.name === 'Bathtub' && 
                                        <li className="list-inline-item my-1 me-4" key={'a'+i}><span className="bg-primary px-2 py-1 rounded d-inline-block text-primary lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('bath')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'Breakfast' ||  v.name === 'Tea/coffee maker' ?
                                        <li className="list-inline-item my-1 me-4" key={'b'+i}><span className="bg-success px-2 py-1 rounded d-inline-block text-success lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('mug-hot')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        :''
                                        }
                                        {v.name === 'WLAN access' && 
                                        <li className="list-inline-item my-1 me-4" key={'g'+i}><span className="bg-warning px-2 py-1 rounded d-inline-block text-warning lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('wifi')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'Kitchen' && 
                                        <li className="list-inline-item my-1 me-4" key={'c'+i}><span className="bg-danger px-2 py-1 rounded d-inline-block text-danger lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('utensils')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'Pet friendly' && 
                                        <li className="list-inline-item my-1 me-4" key={'d'+i}><span className="bg-info px-2 py-1 rounded d-inline-block text-info lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('cat')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'Pool' && 
                                        <li className="list-inline-item my-1 me-4" key={'e'+i}><span className="bg-pink px-2 py-1 rounded d-inline-block text-pink lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('swimmer')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'TV' && 
                                        <li className="list-inline-item my-1 me-4" key={'f'+i}><span className="bg-primary px-2 py-1 rounded d-inline-block text-primary lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('tv')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        </>
                                        ))
                                    }
                                </ul>

                                
                            </div>
                        </Tab>
                        <Tab eventKey="rooms" title="Rooms">
                            <div className="p-3">Rooms</div>
                        </Tab>
                        <Tab eventKey="location" title="Location">
                            <div className="p-3">
                            {isLoaded && 
                            <GoogleMap
                            zoom={15}
                            center={{lat: HotelDtls.htlDtlObj.masterHotel.geocode.latitude, lng: HotelDtls.htlDtlObj.masterHotel.geocode.longitute}}
                            mapContainerStyle={{ width: "100%", height: "500px" }}>
                                { activeMarker &&
                                <InfoWindow onCloseClick={() => setActiveMarker(false)} position={{
                                    lat: HotelDtls.htlDtlObj.masterHotel.geocode.latitude, lng: HotelDtls.htlDtlObj.masterHotel.geocode.longitute
                                    }}
                                    options={{pixelOffset: {width: 0, height: -35}}}>
                                    <div className="blue"><strong>{HotelDtls.htlDtlObj.masterHotel.name}</strong></div>
                                </InfoWindow>
                                }
                                <Marker
                                    position={{lat: HotelDtls.htlDtlObj.masterHotel.geocode.latitude, lng: HotelDtls.htlDtlObj.masterHotel.geocode.longitute}}
                                    onClick={() => setActiveMarker(!activeMarker)}></Marker>
                                        
                                
                            </GoogleMap>
                            }
                            </div>
                        </Tab>
                        <Tab eventKey="amenities" title="Amenities">
                            <div className="p-3">
                                <h3 className="fn15 mb-3">Popular Amenities</h3>
                                <ul className="list-inline fn15 mb-4">
                                    {HotelDtls.htlDtlObj.masterHotel.amenities && HotelDtls.htlDtlObj.masterHotel.amenities.map((v, i) => ( 
                                        <>
                                        {v.name === 'Bathtub' && 
                                        <li className="list-inline-item my-1 me-4" key={'a'+i}><span className="bg-primary px-2 py-1 rounded d-inline-block text-primary lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('bath')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'Breakfast' ||  v.name === 'Tea/coffee maker' ?
                                        <li className="list-inline-item my-1 me-4" key={'b'+i}><span className="bg-success px-2 py-1 rounded d-inline-block text-success lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('mug-hot')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        :''
                                        }
                                        {v.name === 'WLAN access' && 
                                        <li className="list-inline-item my-1 me-4" key={'g'+i}><span className="bg-warning px-2 py-1 rounded d-inline-block text-warning lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('wifi')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'Kitchen' && 
                                        <li className="list-inline-item my-1 me-4" key={'c'+i}><span className="bg-danger px-2 py-1 rounded d-inline-block text-danger lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('utensils')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'Pet friendly' && 
                                        <li className="list-inline-item my-1 me-4" key={'d'+i}><span className="bg-info px-2 py-1 rounded d-inline-block text-info lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('cat')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'Pool' && 
                                        <li className="list-inline-item my-1 me-4" key={'e'+i}><span className="bg-pink px-2 py-1 rounded d-inline-block text-pink lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('swimmer')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        {v.name === 'TV' && 
                                        <li className="list-inline-item my-1 me-4" key={'f'+i}><span className="bg-primary px-2 py-1 rounded d-inline-block text-primary lh-lg op15">&nbsp;<FontAwesomeIcon icon={solid('tv')} className="fn18" />&nbsp;</span> &nbsp;{v.name}</li>
                                        }
                                        
                                        </>
                                        ))
                                    }
                                </ul>

                                <h3 className="fn15 mb-3">Other Amenities</h3>
                                <Row className="g-2">
                                {HotelDtls.htlDtlObj.masterHotel.amenities && HotelDtls.htlDtlObj.masterHotel.amenities.map((v, i) => ( 
                                <Col sm={2} key={'aa'+i}>
                                    <FontAwesomeIcon icon={regular('circle-check')} className="fn16 text-success" />&nbsp; {v.name}
                                </Col>
                                ))
                                }
                                </Row> 
                            </div>
                        </Tab>
                        <Tab eventKey="policies" title="Policies">
                            <div className="p-3">Policies</div>
                        </Tab>
                        <Tab eventKey="reviews" title="Reviews">
                            <div className="p-3">Reviews</div>
                        </Tab>
                    </Tabs>
                </div>
                </>
                :
                <div>Hotel Details Not Available</div>
                }
                </>
                :
                <div>Loading</div>
                }
                </>
                }
            </div>

            
        </div>
    </DefaultLayout>
    </>
  )
}

export default HotelDetails

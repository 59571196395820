import {
  RESET_HOTEL_RES, SET_HOTELRES, SET_OGHOTELRES, 
  RESET_HOTEL_FILTER,
  RESET_HOTELDTL_RES, SET_HOTELDTL_RES, 
  RESET_HOTELCMPR_RES, SET_HOTELCMPR_RES, 
  RESET_DASHHTL_RES,
  SET_DASHHTL_RES,
} from "../types/hotelTypes";
  
const intialState = {
  htlResObj:null,
  htlResOrgObj:null,
  htlDtlObj:null,
  htlCmprObj:null,
  dsBoardDtlObj:null
};

export const hotelListReducer = (state = intialState.htlResObj, { type, payload }) => {
  switch (type) {
    case RESET_HOTEL_RES:
      return { ...state, htlResObj: null, htlResOrgObj: null };
    case SET_OGHOTELRES:
      return { ...state, htlResOrgObj: payload };
    case SET_HOTELRES:
      return { ...state, htlResObj: payload };
    
    default:
      return state;
  }
};

export const hotelDtlsReducer = (state = intialState.htlDtlObj, { type, payload }) => {
  switch (type) {
    case RESET_HOTELDTL_RES:
      return { ...state, htlDtlObj: null};
    case SET_HOTELDTL_RES:
      return { ...state, htlDtlObj: payload };
    default:
      return state;
  }
};

export const hotelCmprReducer = (state = intialState.htlCmprObj, { type, payload }) => {
  switch (type) {
    case RESET_HOTELDTL_RES:
      return { ...state, htlCmprObj: null};
    case SET_HOTELDTL_RES:
      return { ...state, htlCmprObj: payload };
    default:
      return state;
  }
};

export const dsBoardHotelDtlsReducer = (state = intialState.dsBoardDtlObj, { type, payload }) => {
  switch (type) {
    case RESET_DASHHTL_RES:
      return { ...state, dsBoardDtlObj: null};
    case SET_DASHHTL_RES:
      return { ...state, dsBoardDtlObj: payload };
    default:
      return state;
  }
};



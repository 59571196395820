import React, { useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import {Row, Col, Form, Button,} from 'react-bootstrap';
const Support = () => {
  

  return (
    <>
    <DefaultLayout>
        <div className="px-md-4 px-3 py-4">
          <div className="pageHeading px-2"><h2>Support</h2></div>

          <div className="bg-white shadow rounded mb-4">
            <div className="px-3 py-3">
              <Row className="gx-3">
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label className="ms-1 mb-1">Support Type</Form.Label>
                    <Form.Select className="shadow-sm fn14" size="lg">
                      <option value="0">Search by Support Type</option><option value="1">Missing Hotel</option><option value="2">Sync Issues</option><option value="3">Portal Access</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={7}>
                  <Form.Label className="ms-1 mb-1">Subject</Form.Label>
                  <Form.Control className="shadow-sm fn14" size="lg" />
                </Col>

                <Col md={2} className="align-self-center mt-2">
                  <Button variant="primary">Submit Request</Button>
                </Col>
              </Row>
              
              
            </div>
          </div>

          <div className="bg-white shadow-sm rounded">
            <div className="p-3">
              <h3 className="fs-4">Past Request</h3>
              <div className="table-responsive">
                <table className="table table-striped align-middle">
                  <thead>
                    <tr className="align-middle text-uppercase">
                      <th scope="col">Request Type</th>
                      <th scope="col">Date</th>
                      <th scope="col">File</th>
                      <th scope="col">User</th>
                      <th scope="col">Status</th>
                      <th scope="col" className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Extranet Property Mapping</td>
                      <td className="text-nowrap">1 Aug 2022</td>
                      <td>extranet_property_file.xlsx</td>
                      <td>Admin</td>
                      <td><span className="text-success">Complete</span></td>
                      <td className="text-center"><Button variant="success" size="sm">&nbsp;&nbsp;Download&nbsp;&nbsp;</Button></td>
                    </tr>
                    <tr>
                      <td>Real Time Mapping</td>
                      <td className="text-nowrap">10 July 2022</td>
                      <td>extranet_property_file.xlsx</td>
                      <td>Admin</td>
                      <td><span className="text-warning">Pending</span></td>
                      <td className="text-center"><Button variant="success" size="sm">&nbsp;&nbsp;Download&nbsp;&nbsp;</Button></td>
                    </tr>
                    <tr>
                      <td>Booking Verification</td>
                      <td className="text-nowrap">5 March 2022</td>
                      <td>extranet_property_file.xlsx</td>
                      <td>Admin</td>
                      <td><span className="text-danger">Reject</span></td>
                      <td className="text-center"><Button variant="success" size="sm">&nbsp;&nbsp;Download&nbsp;&nbsp;</Button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
          
        </div>
    </DefaultLayout>
    </>
  )
}

export default Support

import { combineReducers } from "redux";
import { hotelListReducer, hotelDtlsReducer, hotelCmprReducer, dsBoardHotelDtlsReducer} from "./hotel";
import { cntQualityListReducer} from "./content";
import { cntSyncListReducer} from "./contentSync";

const reducers = combineReducers({
    hotelList: hotelListReducer,
    hotelDtls: hotelDtlsReducer,
    hotelCmpr: hotelCmprReducer,
    cntQualityList: cntQualityListReducer,
    cntSyncList: cntSyncListReducer,
    dsBoardHotelDtls: dsBoardHotelDtlsReducer
});
export default reducers;
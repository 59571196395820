import {HotelService} from "../../services/hotel.service";
import {
    RESET_HOTEL_RES, SET_HOTELRES, SET_OGHOTELRES, 
    RESET_HOTEL_FILTER,
    RESET_HOTELDTL_RES, SET_HOTELDTL_RES, 
    RESET_HOTELCMPR_RES, SET_HOTELCMPR_RES, 
    RESET_DASHHTL_RES, SET_DASHHTL_RES
} from "../types/hotelTypes";
//import store from '../store';
//import cloneDeep from 'lodash/cloneDeep';

export const doHotelSearch = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_HOTEL_RES})
            dispatch({ type: RESET_HOTEL_FILTER})
            const response = await HotelService.getHotelSearch(reqObj);
            dispatch({ type: SET_OGHOTELRES, payload: response })
            dispatch({ type: SET_HOTELRES, payload: response })
            
        } catch (err) {
            console.log("Hotel Listing Error", err);
        }
    }
}

export const getHotelDtls = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_HOTELDTL_RES})
            const response = await HotelService.getHotelDetails(reqObj);
            dispatch({ type: SET_HOTELDTL_RES, payload: response })
            
        } catch (err) {
            console.log("Hotel Details Error", err);
        }
    }
}

export const getHotelCmpr = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_HOTELCMPR_RES})
            const response = await HotelService.getHotelDetails(reqObj);
            dispatch({ type: SET_HOTELCMPR_RES, payload: response })
            
        } catch (err) {
            console.log("Hotel Compare Error", err);
        }
    }
}

export const dsBoardHotelDtls = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_DASHHTL_RES})
            const response = await HotelService.getDsBoardHotelDtls(reqObj);
            dispatch({ type: SET_DASHHTL_RES, payload: response })
            
        } catch (err) {
            console.log("Dashboard Hotel Error", err);
        }
    }
}





import {ContentSyncService} from "../../services/contentSync.service";
import {RESET_CNTSYNC_RES, SET_CNTSYNCRES } from "../types/contentSyncTypes";

export const doCntSync = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_CNTSYNC_RES})
            const response = await ContentSyncService.getCntSync(reqObj);
            dispatch({ type: SET_CNTSYNCRES, payload: response })
        } catch (err) {
            console.log("Content Sync Error", err);
        }
    }
}